.bid-modal {
  background: #131a22;
}

.fba-orange {
  color: #ff9a00;
}

.color-white {
  color: #fff;
}

.selection-left {
  border-right: 2px solid;
}

.bid-auction-btn {
  font-weight: 700 !important;
  background-color: #ff9a00 !important;
  border-radius: 10px !important;
  margin-left: -20px;
}

.bid-auction-btn:hover {
  opacity: 0.75;
}

.auction-bid-button-title {
  color: #000c1b;
  font-weight: 700;
}

.auction-bid-button-text {
  color: #000c1b;
  font-weight: 300;
  font-size: 13px;
}

.auction-bid-button-footer-text {
  color: #ff4700;
  font-weight: 300;
  font-size: 11px;
}

.auction-modal-input {
  width: 110px;
  border: 2px solid #ccc; /* Set the border color */
  border-radius: 10px; /* Set the border radius */
  padding: 0.25rem; /* Add some padding */
  outline: none; /* Remove the default focus outline */
}

.modal-auction-selection {
  display: flex;
  flex-wrap: wrap;
}

.modal-auction-selection > div {
  width: 50%;
  border: 1px dashed #000c1b;
}

.modal-selection-subtext {
  font-size: 10px;
  font-weight: 600;
  color: #000c1b;
}

.modal-selection-text {
  font-size: 3.5rem;
  color: #ffcb00;
  font-weight: 700;
}

.error {
  color: red;
}

/* password visibility */

.show-password,
.hide-password {
  display: inline-block;
  margin-left: 0.5em;
  transition: transform 0.5s ease-in-out;
}

.show-password:not(.hide-password) {
  transform: rotate(0deg);
}

.hide-password:not(.show-password) {
  transform: rotate(180deg);
}

.prod-name {
  padding: 0.75rem 0 !important;
}

.bid-text {
  font-size: 1.5rem;
  min-width: 50px;
}

.bid-auction-btn-disabled {
  background-color: #384859;
}

.winning-closed-number {
  background-color: #ffcb00;
  color: #000;
}

.winning-closed-number-text {
  color: #000;
  font-weight: 100 !important;
}

.amazon-secondary-button:hover {
  opacity: 0.75;
}

/* .amazon-secondary-button:focus {
  background-color: red !important;
} */

.notFound {
  background-color: #fff;
}

.PhoneInputInput {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.css-13cymwt-control:hover {
  border-color: #ff9a00;
}

.css-t3ipsp-control:hover {
  border-color: #ff9a00 !important;
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 1px #ff9a00;
}

p.prod-date {
  display: none;
}

.test-btn {
  color: #fff !important;
}

.test-btn:hover {
  color: black !important;
}

.n-btn {
  padding: 0.65rem;
  border-radius: 0.75rem;
}

.n-btn:hover {
  background: #272833;
}
